import { Injectable } from "@angular/core";
import { SendReceiveService } from '../../../services/common/sendReceive.service';
import { MyAppHttpService } from '../../../services/common/my-app-http.service';

@Injectable({
  providedIn: 'root'
})
export class MyjobsService {

  constructor(private sendReceiveService: SendReceiveService) { }

  getAllEntPostedJobs(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getAllEntPostedJobs, requestData);
  }

  getAllAppliedProfilesForJob(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.getAllAppliedProfilesForJob, requestData);
  }

  getActiveorDeactiveProfilesForJob(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateJobActiveOrInactive, requestData);
  }

  getProposedOffer(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.clickAndHireAProfile, requestData);
  }

  updateprofileInteresed(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.updateProfileInterestedIn, requestData);
  }

  getJobDetails(requestData) {
    return this.sendReceiveService.send(this.sendReceiveService.httpVerb.Post, MyAppHttpService.REQUESTS.GetJobDetailsByJobId, requestData);
  }
}


