import { ViewFeedBackComponent } from './../viewfeedback/viewfeedback.component';
import { NgModule } from "@angular/core";
import { ProfileInfoComponent } from "./profile-info.component";
import { DropDownListModule } from "@syncfusion/ej2-ng-dropdowns";
import { MultiSelectAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { TypeaheadModule } from "ngx-type-ahead";
import { CommonPipesModule } from "../../../pipes/common-pipes/common-pipes.module";
import { StarRatingModule } from "angular-star-rating";
import { AppMaterialModule } from "../../../app-material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { PopoverModule } from "ngx-smart-popover";
import { NgxPaginationModule } from "ngx-pagination";
import { UpdateInterviewComponentModule } from "../modal/updateinterview/updateinterview.module";
import { UpdateInterviewComponent } from "../modal/updateinterview/updateinterview.component";
// import { ViewRefFBComponent } from "./view-ref-fb/view-ref-fb.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ProfileInfoComponent,
    // AutoTestSearchFilterPipe, 
    // TableDisplayComponent
    // AvatarFilter
    // ViewRefFBComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    AppMaterialModule,
    StarRatingModule.forRoot(),
    CommonPipesModule,
    TypeaheadModule,
    MultiSelectAllModule,
    DropDownListModule,
    PopoverModule,
    NgxPaginationModule,
    UpdateInterviewComponentModule,
    NgbModule
   
  ],
  exports: [ProfileInfoComponent],
  entryComponents: [UpdateInterviewComponent,
    // ViewRefFBComponent
  ]
})
export class ProfileInfoModule { }
